<template>
  <v-dialog
    v-model="dialogFornecedor"
    @click:outside="$emit('update:dialogFornecedor', false)"
    @keydown.esc="$emit('update:dialogFornecedor', false)"
    max-width="1300px"
    scrollable
  >
    <v-card flat :loading="loading">
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{
          edit
            ? $tc("global.editar") + " " + $tc("global.fornecedor")
            : $tc("global.add") + " " + $tc("global.fornecedor")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogFornecedor', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row class="pt-5">
            <!-- Nome -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="fornecedor.nome"
                :label="$tc('global.nome') + ' ' + $tc('global.fornecedor')"
                dense
                outlined
                hide-details
                :rules="formRules"
                required
              ></v-text-field>
            </v-col>
            <!-- Documento -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="fornecedor.documento"
                :label="$tc('global.documento')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <!-- Categoria -->
            <v-col cols="12" md="3">
              <v-combobox
                v-model="selectedCategoria"
                :label="$tc('global.categoria')"
                :items="categorias"
                dense
                outlined
                :rules="formRules"
                required
                hide-details
                item-text="descricao"
                item-value="id"
              ></v-combobox>
            </v-col>
            <!-- Categoria -->
            <v-col cols="12" md="3">
              <v-combobox
                v-model="selectedTipo"
                :label="$tc('global.tipo')"
                :items="tipos"
                dense
                outlined
                :rules="formRules"
                required
                disabled
                item-text="descricao"
                item-value="id"
                hide-details
              ></v-combobox>
            </v-col>
            <!-- Localizacao -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fornecedor.localizacao"
                :label="$tc('global.localizacao')"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <!-- Cidade -->
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="selectedCidade"
                :items="cidades"
                :loading="buscandoCidades"
                :search-input.sync="searchCidade"
                @keyup="getCidades"
                dense
                outlined
                hide-details
                item-text="nome"
                item-value="id"
                :placeholder="$tc('global.cidade')"
                append-icon=""
                full-width
                return-object
                autocomplete="off"
                class="remove-underline input-font-size"
              ></v-autocomplete>
            </v-col>
            <!-- Moeda -->
            <v-col cols="12" md="4">
              <v-combobox
                v-model="selectedMoeda"
                :label="$tc('global.moeda')"
                :items="moedas"
                dense
                outlined
                item-text="sigla"
                item-value="id"
                hide-details
              ></v-combobox>
            </v-col>
            <!-- Endereco -->
            <v-col cols="12" md="8">
              <v-text-field
                v-model="fornecedor.endereco"
                :label="$tc('global.endereco')"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <!-- Email -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fornecedor.email"
                :label="$tc('global.email')"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <!-- telefone -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="fornecedor.telefone"
                :label="$tc('global.telefone')"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <!-- Celular -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="fornecedor.celular"
                :label="$tc('global.celular')"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="">
        <v-spacer></v-spacer>
        <v-btn
          color="button_2"
          class="white--text"
          depressed
          small
          :disabled="!validForm"
          :loading="loading"
          @click="edit ? updateFornecedor() : createFornecedor()"
        >
          {{ edit ? $tc("global.salvar") : $tc("global.criar") }}
          <v-icon right>{{ edit ? "mdi-plus" : "mdi-check" }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { getFornecedoresCategoriasAtivo } from "@/api/fornecedores/categorias_fornecedores.js";
import { mapState } from "vuex";
import { searchCidades } from "@/api/cidades/cidades";
import { getFornecedoresTiposAtivo } from "@/api/fornecedores/tipos_fornecedores.js";
import {
  getFornecedorCombobox,
  postFornecedorCombobox,
  putFornecedorCombobox,
} from "@/api/fornecedores/fornecedores.js";
export default {
  name: "DialogFornecedor",

  components: {
    // DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    dialogFornecedor: {
      type: Boolean,
      required: true,
    },
    fornecedor_id: {
      type: [String, Number],
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      validForm: true,
      buscandoCidades: false,
      searchCidade: "",
      formRules: [inputRequired],
      emailRules: [validaEmail],
      fornecedor: {},
      categorias: [],
      cidades: [],
      tipos: [],
      selectedCidade: null,
      selectedCategoria: null,
      selectedMoeda: null,
      selectedTipo: null,
      fornecedor_original: null,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  watch: {
    selectedCategoria() {
      if (this.selectedCategoria) {
        this.fornecedor.categoria_id = this.selectedCategoria.id;
      }
    },
    selectedTipo() {
      if (this.selectedTipo) {
        this.fornecedor.tipo_id = this.selectedTipo.id;
      }
    },
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.fornecedor.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedCidade() {
      if (this.selectedCidade) {
        this.fornecedor.cidade_id = this.selectedCidade.id;
      }
    },
  },

  methods: {
    getCategorias() {
      return getFornecedoresCategoriasAtivo().then((response) => {
        this.categorias = response;
      });
    },
    getTipos() {
      return getFornecedoresTiposAtivo().then((response) => {
        this.tipos = response;
        this.selectedTipo = this.tipos[1];
      });
    },
    async searchCidades(filtro) {
      return searchCidades(filtro).then((response) => {
        this.cidades = response;
      });
    },

    async getCidades() {
      if (this.searchCidade) {
        if (this.searchCidade.length > 2 && event.key != "Backspace") {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
    },

    async getFornecedorCombobox() {
      await getFornecedorCombobox(this.fornecedor_id).then((response) => {
        this.fornecedor = response;
        this.fornecedor_original = { ...response };
        this.selectedMoeda = this.moedas.find((moeda) => {
          return moeda.id_moeda === this.fornecedor.moeda_id;
        });
        this.selectedCategoria = this.categorias.find((categoria) => {
          return categoria.id === this.fornecedor.categoria_id;
        });
        this.selectedTipo = this.tipos.find((tipo) => {
          return tipo.id === this.fornecedor.tipo_id;
        });
      });
      if (this.fornecedor.cidade_id) {
        await this.searchCidades(`?search=id=${this.fornecedor.cidade_id}`);
        this.selectedCidade = this.cidades[0];
      }
    },

    createFornecedor() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const fornecedor = new FormData();
        let fornecedor_2 = {};
        fornecedor_2.nome = this.fornecedor.nome;
        fornecedor_2.categoria_id = this.fornecedor.categoria_id;
        fornecedor_2.tipo_id = this.fornecedor.tipo_id;
        fornecedor_2.documento = this.fornecedor.documento;
        fornecedor_2.endereco = this.fornecedor.endereco;
        fornecedor_2.localizacao = this.fornecedor.localizacao;
        fornecedor_2.cidade_id = this.fornecedor.cidade_id;
        fornecedor_2.email = this.fornecedor.email;
        fornecedor_2.telefone = this.fornecedor.telefone;
        fornecedor_2.celular = this.fornecedor.celular;
        fornecedor_2.moeda_id = this.fornecedor.moeda_id;

        for (let key in fornecedor_2) {
          if (
            fornecedor_2[key] !== null &&
            fornecedor_2[key] !== undefined &&
            fornecedor_2[key] !== ""
          ) {
            fornecedor.append(key, fornecedor_2[key]);
          }
        }

        postFornecedorCombobox(fornecedor)
          .then((response) => {
            this.$emit("update:dialogFornecedor", false);
            // this.$emit("fetch-fornecedores", response.data.result);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error(this.$tc("global.jaExisteCliente"));
            } else {
              this.$toast.error(this.$tc("global.erroCliente"));
            }
            console.log(error.response);
          });
      }
    },

    updateFornecedor() {
      if (
        JSON.stringify(this.fornecedor) ===
        JSON.stringify(this.fornecedor_original)
      ) {
        this.$toast.error("¡Haz un cambio antes de guardar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const fornecedor = new FormData();

        let fornecedor_2 = {};
        fornecedor_2.nome = this.fornecedor.nome;
        fornecedor_2.categoria_id = this.fornecedor.categoria_id;
        fornecedor_2.tipo_id = this.fornecedor.tipo_id;
        fornecedor_2.documento = this.fornecedor.documento;
        fornecedor_2.endereco = this.fornecedor.endereco;
        fornecedor_2.localizacao = this.fornecedor.localizacao;
        fornecedor_2.cidade_id = this.fornecedor.cidade_id;
        fornecedor_2.email = this.fornecedor.email;
        fornecedor_2.telefone = this.fornecedor.telefone;
        fornecedor_2.celular = this.fornecedor.celular;
        fornecedor_2.moeda_id = this.fornecedor.moeda_id;

        for (let key in fornecedor_2) {
          if (
            fornecedor_2[key] !== null &&
            fornecedor_2[key] !== undefined &&
            fornecedor_2[key] !== ""
          ) {
            fornecedor.append(key, fornecedor_2[key]);
          }
        }

        putFornecedorCombobox(this.fornecedor.id, fornecedor)
          .then(() => {})
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.$toast.error(this.$tc(error.response.data.message));
          });
      }
    },
  },

  async mounted() {
    await this.getCategorias();
    await this.getTipos();

    if (this.fornecedor_id) {
      this.getFornecedorCombobox();
    }
    if (!this.edit) {
      this.selectedMoeda = this.moedas.find(
        (moeda) => moeda.descricao === "GUARANI"
      );
    }
    this.loading = false;
  },
};
</script>

<style></style>
